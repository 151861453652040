.spinner {
    border: 4px solid rgba(0,0,0,0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  


  .katex {
    font-size: 0.9em; /* Anpassen, um die optimale Größe zu finden */
    overflow-x: auto; /* Ermöglicht horizontales Scrollen, falls notwendig */
}

.chattext {
  word-wrap: break-word; /* Ensures words can break and wrap onto the next line */
  max-width: 100%; /* Ensures the container does not overflow its parent */
}

.math {
  overflow-x: auto; /* Ermöglicht das Scrollen horizontal, falls die Formel zu breit ist */
 /* display: block; /* Stellt sicher, dass Formeln als Block angezeigt werden (was den Zeilenumbruch nach sich zieht) */
  text-align: left; /* Aligns the math formulas to the left */
  word-wrap: break-word;
  max-width: 100%;
  font-size: 0.5em; /* Anpassen, um die optimale Größe zu finden */
}
/*
@media (max-width: 768px) {
  .chattext {
    font-size: 0.8em; 
  }
}

@media (max-width: 480px) {
  .chattext {
    font-size: 0.7em; 
  }
}*/